import React, { useState } from 'react';
import { Toast, Button } from 'react-bootstrap';

function ToastExample({ text, visible, setVisible }) {
  return (
    <div style={{
      position: 'fixed',
      top: '1rem',
      right: '1rem',
      zIndex: 1050 // Adjust as necessary
    }}>
      <Toast onClose={() => setVisible(false)} show={visible} delay={3000} autohide>
        <Toast.Header>
          {/* <Button onClick={() => setShow(true)}>Email Adresinizi Kontrol Ediniz.</Button> */}
        </Toast.Header>
        <Toast.Body>{text}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ToastExample;
