import axios from "axios";

const baseURL = "https://denkaa.com/api";

const getTokenFromStorage = () => {
  const token = localStorage.getItem("token");
  return token;
};

const service = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json', 
  },
});

service.interceptors.request.use(
  async (config) => {
    const token = getTokenFromStorage();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
