import Service from "../baseUrl";
// import { GetDepartmentWithPaginationQuery } from "../../types/DepartmentReqType";

export class UserService {
  async CreateUser(body:any) {
    const response = await Service.post("/user/create", body);
    return response.data;
  }
  async ReserPassword(body:any) {
    const response = await Service.post("/user/resetpassword", body);
    return response.data;
  }
  async UserUpdate(body:any) {
    const response = await Service.put("/user", body);
    return response.data;
  }
  async PasswordUpdate(body:any) {
    const response = await Service.put("/user/password", body);
    return response.data;
  }
  async Login(body:any) {
    const response = await Service.post("/user/login", body);
    return response.data;
  }
  async GetMe() {
    const response = await Service.get("/user/getMe");
    return response.data;
  }
}
