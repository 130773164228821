import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const userSlice = createSlice({
    name: "wishlist",
    initialState: {
        userInfo: {}
    },
    reducers: {

        setAuth(state, action){
            state.userInfo = action.payload
        }
    },
});

export const { setAuth } = userSlice.actions;
export default userSlice.reducer;
